// Colors
$local-topbar: #333333;
$local-topbar-text: #ffffff;
$local-bkg: #ff6666;
$local-bkg-image: none;
$local-text: #ffffff;
$local-ad-background: #222222;

// Font
$local-text-font: 'Open Sans', sans-serif;
$local-headline-font: 'Oswald', sans-serif;

// Dimensions
$local-topbar-height: 70px;
$local-logo-width: 330px;
$local-logo-margin-top: 7%;
$local-logo-margin-bottom: 5%;
